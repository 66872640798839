<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <div class="bg-white poppins">
      <div class="modal-body d-flex flex-column h-175px scroll-y mx-5">
        <div class="d-flex justify-center">
          <v-radio-group row v-model="type" mandatory>
            <v-radio label="Attempts" value="attempts" />
            <v-radio label="Delivered" value="delivered" />
          </v-radio-group>
        </div>
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button class="btn btn-light mr-3 px-5 py-2" @click="toggleModal">
            Cancel
          </button>
          <button type="submit" class="btn btn-info px-5 py-2" @click="submit">
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ExportPopup",
  data: () => ({
    dialog: false,
    type: "Attempts",
  }),
  methods: {
    submit() {
      this.$emit("setExportType", this.type);
      this.toggleModal();
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style>
.v-radio label {
  margin-bottom: 0;
}
</style>
