<template>
  <div class="p-4 bg-white rounded-lg custom-container-height">
    <div class="mb-10 mx-4 mt-6 d-flex align-center justify-space-between">
      <datatable-detail
        class="col-12 col-sm-12 col-md-9"
        :table_properties="tableProperties"
      ></datatable-detail>
      <div v-if="hasExport" class="mr-4">
        <button @click="handleExport" class="btn btn--export-filter">
          <span class="svg-icon">
            <v-icon size="18">mdi-export-variant</v-icon>
          </span>
          Export
        </button>
        <Export
          ref="export"
          :headers="exportHeaders"
          :downloadItem="downloadItem"
          class="d-none"
        ></Export>
      </div>
    </div>

    <ExportPopup ref="exportPopup" @setExportType="setExportType" />

    <div class="mx-2 mb-6">
      <Filters
        ref="filter"
        :submitFilterValues="submitFilterValues"
        :resetFilterValues="resetFilterValues"
        :filters="filter"
        :setTableFiltersValues="setTableFiltersValues"
      ></Filters>
    </div>
    <template v-if="dataTableQuantity > 0">
      <div class="mb-6" v-for="index in dataTableQuantity" :key="index - 1">
        <span
          class="pl-8 poppins text-dark-65 font-size-h1 display5-md font-weight-medium"
        >
          {{ $store.getters.getREPORTDATATABLETitlesOfDataTables[index - 1] }}
        </span>
        <v-data-table
          fixed-header
          light
          dense
          :items="items[index - 1]"
          :headers="headers[index - 1]"
          :items-per-page="table_state[index - 1].per_page"
          :page.sync="table_state[index - 1].page"
          class="m-2 mt-4 px-6 font-weight-bold elevation-1"
          item-class="headerZindex"
          hide-default-footer
          mobile-breakpoint="300"
        >
          <template v-slot:no-data> NO DATA HERE!</template>
          <template v-slot:no-results> NO RESULTS HERE!</template>
          <template
            v-for="(head, i) in headers[index - 1]"
            v-slot:[`item.`+head.value]="{ value }"
          >
            <span class="second-text" :key="i">{{
              value === "-" ? "" : value
            }}</span>
          </template>

          <template #item.Warehouse="{ value }">
            <span class="first-text">{{ value === "-" ? "" : value }}</span>
          </template>
          <template #item.Client="{ value }">
            <span class="first-text">{{ value }}</span>
          </template>
          <template #item.Status="{ value }">
            <StatusColumn
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></StatusColumn>
          </template>
          <template #item.AO="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.A1="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.A2="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.A3="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.A4="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.A5="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.AA="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.DO="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.D1="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.D2="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.D3="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.D4="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.D5="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
          <template #item.DD="{ value }">
            <PercentColumn
              :max-value="maxValues[index - 1]"
              class="d-flex justify-center align-content-start min-w-90px"
              :value="value"
            ></PercentColumn>
          </template>
        </v-data-table>
        <div class="pt-4 d-flex justify-space-between align-center flex-wrap">
          <v-col class="pr-0" cols="6" sm="3" lg="2" order="0">
            <PageInfo
              :page="table_state[index - 1].page"
              :totalNumber="items[index - 1].length"
              :perPage="table_state[index - 1].per_page"
            ></PageInfo>
          </v-col>
          <v-col cols="12" sm="7" lg="8" order-sm="1" order="2">
            <v-pagination
              v-model="table_state[index - 1].page"
              :length="
                Math.ceil(
                  items[index - 1].length / table_state[index - 1].per_page
                )
              "
              total-visible="7"
              color="#a929e6"
              class="poppins"
              flat
            ></v-pagination>
          </v-col>

          <v-col cols="6" sm="2" order-sm="2" order="1">
            <v-select
              v-model="table_state[index - 1].per_page"
              class="poppins"
              :items="pages"
              color="#a929e6"
              dense
              flat
              outlined
              hide-details
            ></v-select>
          </v-col>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Filters from "@/own/components/filter/Filters.vue";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import PageInfo from "@/own/components/pagination/PageInfo.vue";
import Swal from "sweetalert2";
import Export from "@/own/components/Export.vue";
import StatusColumn from "@/own/components/datatable/StatusColumn";
import PercentColumn from "@/own/components/datatable/PercentColumn";
import {
  EXPORT_REPORT_AGENT_DATA,
  GET_REPORT_AGENT_DATA,
  SET_REPORT_DATATABLE_RESULT,
  SHOW_REPORT_DATATABLE,
} from "@/core/services/store/reportAgent.module";
import ExportPopup from "@/own/components/reports/ExportPopup";

export default {
  name: "ReportDataTable",
  props: {
    /**
     * @description urls to fetch chart data
     * @type {{data_url: string, show_url: string}}
     */
    urls: {
      required: true,
      type: Object,
      default: () => ({ data_url: String, show_url: String }),
    },
    /**
     * @description for setting filters mandatory status
     * @values true, false, ['some_filter_name']
     * @type {boolean, [{title: string, name: string, valueFieldName: string}]}
     */
    mandatoryFilter: {
      type: [Boolean, Array],
      default: false,
    },
  },
  components: {
    ExportPopup,
    Export,
    Filters,
    DatatableDetail,
    PageInfo,
    StatusColumn,
    PercentColumn,
  },
  beforeMount() {
    this.$store.dispatch(GET_REPORT_AGENT_DATA, this.urls.data_url);
    this.$store.commit(SET_REPORT_DATATABLE_RESULT, []);
  },
  data: () => ({
    filtersData: [],
    exportType: null,
    table_state: [
      {
        page: 1,
        per_page: 50,
      },
      {
        page: 1,
        per_page: 50,
      },
    ],
  }),
  computed: {
    pages: function () {
      return [25, 50, 75, 100];
    },
    tableProperties: function () {
      return this.$store.getters.getREPORTAGENTProperties;
    },
    headers: function () {
      return this.$store.getters.getREPORTDATATABLEResultHeaders;
    },
    items: function () {
      return this.$store.getters.getREPORTDATATABLEResultBody;
    },
    maxValues: function () {
      return this.$store.getters.getREPORTDATATABLEMaxValue;
    },
    hasExport: function () {
      return this.$store.getters.getREPORTAGENTHasExport;
    },
    exportHeaders: function () {
      let headers = [],
        counter = 0;
      this.hasExport &&
        this.filter.forEach((item) => {
          const obj = {
            order: counter++,
            text: item.title,
            value: item.name,
          };
          headers.push(obj);
        });
      return headers;
    },
    filter: function () {
      return this.$store.getters.getREPORTAGENTFilters;
    },
    dataTableQuantity: function () {
      return this.$store.getters.getREPORTDATATABLEQuantity;
    },
  },
  watch: {
    exportHeaders: function () {
      this.$nextTick(() => {
        this.$refs.export.$data.columns_to_export = this.exportHeaders;
        this.$refs.export.$data.columns_to_export.forEach((col, index) => {
          this.$refs.export.$data.columns_to_export[index] = {
            exportable: true,
            ...col,
          };
        });
      });
    },
  },
  methods: {
    updateTableContent() {
      this.$forceUpdate();
    },
    setExportType(exportType) {
      this.exportType = exportType;
      this.$refs.export.exportColumns();
    },
    handleExport() {
      if (this.isAnyFilterSelected()) {
        this.$refs.exportPopup.toggleModal();
      } else {
        Swal.fire({
          title: "Warning",
          text: `Please, select at least one column to export!`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    /**
     * validate requirement of filters
     * @returns Boolean
     */
    validateFilterValues() {
      let is_valid = true;
      // if mandatoryFilter array then checks are all filters located in array are selected
      if (Array.isArray(this.mandatoryFilter)) {
        this.mandatoryFilter.forEach((filter) => {
          let is_exist = false;
          this.filtersData.forEach((data) => {
            if (data.name === filter.name + filter.valueFieldName) {
              if (
                data.value !== "" &&
                data.value !== null &&
                data.value !== undefined
              ) {
                is_exist = true;
              }
            }
          });
          if (!is_exist) {
            is_valid = false;
          }
        });
      }
      // if mandatoryFilter is true then check all filter values are selected
      else if (this.mandatoryFilter) {
        this.filters.forEach((filter) => {
          let is_exist = false;
          this.filtersData.forEach((data) => {
            if (data.name === filter.name) {
              is_exist = true;
            }
          });
          if (!is_exist) {
            is_valid = false;
          }
        });
      }
      return is_valid;
    },
    submitFilterValues() {
      if (this.filtersData && this.validateFilterValues()) {
        this.$store.dispatch(SHOW_REPORT_DATATABLE, {
          url: this.urls.show_url,
          filtersData: this.filtersData,
        });
      } else {
        if (Array.isArray(this.mandatoryFilter)) {
          let message = this.mandatoryFilter
            .map((filter) => filter.title)
            .join(",");
          Swal.fire(
            "Warning!",
            `Select ${message} filter${
              this.mandatoryFilter.length > 1 ? "s" : ""
            }!`,
            "warning"
          );
        } else if (this.mandatoryFilter) {
          Swal.fire("Warning!", "Select all filters!", "warning");
        }
      }
    },
    checkDateFilter() {
      let result = false;
      if (this.filtersData) {
        this.filtersData.forEach((item) => {
          if (item.name === "date_range.date") result = true;
        });
      }
      return result;
    },
    setTableFiltersValues(val) {
      this.filtersData = val;
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$refs.filter.$refs.filter.forEach((filter) => {
        filter.clearData();
      });
      this.filtersData = [];
      this.$store.dispatch(GET_REPORT_AGENT_DATA, this.urls.data_url);
      this.$store.commit(SET_REPORT_DATATABLE_RESULT, []);
      this.$store.commit(SET_PAGE_LOADING, false);
    },
    isAnyFilterSelected() {
      return this.filtersData.length > 0;
    },
    downloadItem(columns) {
      if (this.isAnyFilterSelected()) {
        this.$store.commit(SET_PAGE_LOADING, true);
        const payload = {
          type: this.exportType,
          columns: columns,
          filters: this.filtersData,
        };
        this.$store
          .dispatch(EXPORT_REPORT_AGENT_DATA, {
            dataUrl: this.urls.data_url,
            payload,
          })
          .then(() => {
            if (this.$store.getters.getREPORTAGENTExportUrl) {
              const link = document.createElement("a");
              link.href = this.$store.getters.getREPORTAGENTExportUrl;
              link.download = "Export.csv";
              link.click();
              link.remove();
            }
            setTimeout(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            }, 1500);
            this.$refs.export.toggleModal();
          });
      } else
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one filter!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
    },
  },
};
</script>

<style scoped>
.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 126px) !important;
  }
}
</style>
