<template>
  <span :class="btnColorSetter(value)"> {{ value }} % </span>
</template>

<script>
/**
 * dedicated to use in orders status but used on cod too
 * api name = orderStatus
 * @type [ "badge-light-info", "badge-light-warning", "badge-light-danger", "badge-light-primary", "badge-light-success",]
 */
export default {
  name: "StatusColumn",
  props: ["value", "maxValue"],
  methods: {
    btnColorSetter(value) {
      let res = "text-center badge font-size-sm my-1 py-2 px-4";
      const numberValue = value * 1;
      if (numberValue == this.maxValue) {
        return res + " badge-light-info";
      } else if (numberValue > 95) {
        return res + " badge-light-success";
      } else if (numberValue > 85) {
        return res + " badge-light-warning";
      } else {
        return res + " badge-light-danger";
      }
    },
  },
};
</script>

<style scoped>
.status-active,
.status-confirmed,
.status-picked,
.status-delivered,
.status-partially-picked,
.badge-light-success {
  color: #14b519;
  background-color: #e1f7e3 !important;
}
</style>
